import splide, { customSplide } from "../utils/splide";

const banner = document.getElementById("banner");
if (banner) {
    const bannerSplide = customSplide(banner, {
        type: "fade",
        rewind: true,
        perPage: 1,
        autoplay: true,
        arrows: true,

        speed: 6000,
        lazyLoad: "sequential",
        pagination: true,
        arrows: banner.dataset.slides > 1 ? true : false,
        breakpoints: {
            1200: {
                pagination: true,
                arrows: false,
            },
        },
    });
    bannerSplide.mount();

    bannerSplide.on("moved", function() {
      document.querySelector("li.is-visible .banner-image").classList.add("animated")
      document.querySelector("li.is-visible .banner-splide__content p").classList.add("content-animated")
    })
    bannerSplide.on("move", function() {
      document.querySelector("li.is-visible .banner-image").classList.remove("animated")
      document.querySelector("li.is-visible .banner-splide__content p").classList.remove("content-animated")
    })
}

const video = document.getElementById("videos");
if (video) {
    const videoSplide = splide(video, {
        perPage: 2,
        arrows: true,
        breakpoints: {
            1200: {
                perPage: 2,
                pagination: true,
                arrows: false,
            },
            600: {
                perPage: 1,
                pagination: true,
                arrows: false,
            },
        },
    });
    videoSplide.mount();
}


// TODO Conatains classList
document.addEventListener("DOMContentLoaded", () => {

  document.querySelectorAll("#banner-list li.banner-splide__item").forEach(el => {
    if(el.classList.contains("is-active")) {
      // console.log(el)
    }
  })
  // document.querySelectorAll(".bannerImage").forEach(el => {
  //   el.classList
  // })

  // const bannerImage = document.querySelectorAll(".bannerImage")
  
  // function animated() {
  //   bannerImage.forEach(el => {
  //     el.classList.add("animated")
  //   })
  // }
  
  // banner.addEventListener("transitionend", animated);
})
